import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { teacheResetPassAction, teacherDeleteAction, teacherSuspendAction } from '../Redux/Action/TeacherAction';
import { studentDeleteAction, studentResetPassAction, studentSuspendAction } from '../Redux/Action/studentAction';
import { schoolResetPassAction } from '../Redux/Action/schoolAction';
import { notificationDeleteAction, notificationListAction } from '../Redux/Action/NotificationAction';
import { ManageAdminDeleteAction, SubAdminSuspendAction } from '../Redux/Action/ManageAdminAction';
import { adminSubResetPasswordAction } from '../Redux/Action/AuthAction';
import { ManageContentDeleteAction } from '../Redux/Action/ManageContentAction';
import { parentDeleteAction, parentResetPassAction, parentSuspendAction } from '../Redux/Action/ParentAction';

const ConfirmModal = ({ isModalOpen, handleConfirmClose, data, selectedType }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()





    const handleDeleteNotification = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(notificationDeleteAction(value))
            if (res?.payload?.statusCode == 200) {
                navigate("/PushNotifications");
                handleConfirmClose()
                const payload = {
                    page: 0,
                    limit:20
                  }
                dispatch(notificationListAction(payload))
                toast.success("Notifications Deleted Successfully", {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handleSubAdminDelete = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(ManageAdminDeleteAction(value))
            if (res?.payload?.statusCode == 200) {
                navigate("/ManageAdmins");
                handleConfirmClose()

                toast.success("Notifications Deleted Successfully", {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handleDeleteContentVideo = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(ManageContentDeleteAction(value))
            if (res?.payload?.statusCode == 200) {
                navigate("/ManageContent");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }








    const handleteacherSuspend = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(teacherSuspendAction(value))
            if (res?.payload?.statusCode == 200) {
                // navigate("/Teachers");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }




    const handleStudentSuspend = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(studentSuspendAction(value))
            if (res?.payload?.statusCode == 200) {
                // navigate("/Students");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handleSubAdminSuspend = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(SubAdminSuspendAction(value))
            if (res?.payload?.statusCode == 200) {
                navigate("/ManageAdmins");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handlesubAdminResetPassword = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(adminSubResetPasswordAction(value))
            if (res?.payload?.statusCode == 200) {
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }



    const handleteacherDelete = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(teacherDeleteAction(value))
            if (res?.payload?.statusCode == 200) {
                navigate("/Teachers");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handleStudentDelete = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(studentDeleteAction(value))
            if (res?.payload?.statusCode == 200) {
                navigate("/Students");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }



    const handleResetPassword = async (type) => {
        try {
            data = {
                id: data,
            }
            let res;
            if (type === "studentResetPassword") {
                res = await dispatch(studentResetPassAction(data))
            } else if (type === "teacherResetPassword") {
                res = await dispatch(teacheResetPassAction(data))
            } else {
                res = await dispatch(schoolResetPassAction(data))
            }
            if (res?.payload?.statusCode == 200) {
                if (type === "studentResetPassword") {
                    handleConfirmClose()
                } else if (type === "teacherResetPassword") {
                    // navigate("/Teachers");
                    handleConfirmClose()
                } else {
                    handleConfirmClose()
                }
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }




    const handleParentDeleteAction = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(parentDeleteAction(value))
            if (res?.payload?.statusCode == 200) {
                navigate("/parents");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    



    const handleParentresetPassAction = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(parentResetPassAction(value))
            if (res?.payload?.statusCode == 200) {
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    

    const handleParentActivateAction = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(parentSuspendAction(value))
            if (res?.payload?.statusCode == 200) {
                // navigate("/parents");
                handleConfirmClose()
                toast.success(res?.payload?.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
            
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }






    return (
        <Modal
            show={isModalOpen}
            onHide={handleConfirmClose}
            className="comn-modal-set"
        >

            {selectedType === "studentResetPassword" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Reset Password

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to  Reset Password?
                        </p>
                    </Modal.Body>
                </>

            }

            {selectedType === "schoolResetPassword" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Reset Password

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to  Reset Password?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "subAdminrestPassword" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Reset Password

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to  Reset Password?
                        </p>
                    </Modal.Body>
                </>

            }




            {selectedType === "parentResetPassword" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Reset Password

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to  Reset Password?
                        </p>
                    </Modal.Body>
                </>

            }



            {selectedType === "teacherResetPassword" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Reset Password

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to  Reset Password?
                        </p>
                    </Modal.Body>
                </>

            }




            {selectedType === "parentDelete" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Account Delete

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }



            {selectedType === "NotificationDelete" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Notification Delete

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }

            {selectedType === "StudentDelete" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Student Delete

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "deleteContentVideo" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Video Delete

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }






            {selectedType === "teacherDelete" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Delete Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "subAdminDelete" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Delete Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }

            {selectedType === "teacherSuspend" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Suspend Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to Suspend?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "parentSuspend" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Suspend Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to Suspend?
                        </p>
                    </Modal.Body>
                </>

            }






            {selectedType === "parentActiveAccount" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Activate Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to activate this account?
                        </p>
                    </Modal.Body>
                </>

            }



            {selectedType === "teacherActiveAccount" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Activate Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to activate this account?
                        </p>
                    </Modal.Body>
                </>

            }




            {selectedType === "StudentSuspend" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Suspend Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to Suspend?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "studentActiveAccount" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Activate Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to activate this account?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "subAdminSuspend" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Suspend Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to Suspend?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "subAdminActiveAccount" &&

                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Activate Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to activate this account?
                        </p>
                    </Modal.Body>
                </>

            }


            <Modal.Footer className="border-none justify-content-center modal-footer">
                {
                    selectedType === "teacherResetPassword" &&
                    <Button
                        onClick={() => handleResetPassword("teacherResetPassword")}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }

                {
                    selectedType === "studentResetPassword" &&
                    <Button
                        onClick={() => handleResetPassword("studentResetPassword")}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }

                {
                    selectedType === "schoolResetPassword" &&
                    <Button
                        onClick={() => handleResetPassword("schoolResetPassword")}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {
                    selectedType === "NotificationDelete" &&
                    <Button
                        onClick={() => handleDeleteNotification()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn"
                    >
                        Yes
                    </Button>
                }


                {selectedType === "teacherSuspend" &&
                    <Button
                        onClick={() => handleteacherSuspend()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {selectedType === "teacherActiveAccount" &&
                    <Button
                        onClick={() => handleteacherSuspend()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }

                {selectedType === "teacherDelete" &&
                    <Button
                        onClick={() => handleteacherDelete()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {selectedType === "studentActiveAccount" &&
                    <Button
                        onClick={() => handleStudentSuspend()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn"
                    >
                        Yes
                    </Button>
                }



                {selectedType === "StudentSuspend" &&
                    <Button
                        onClick={() => handleStudentSuspend()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {selectedType === "subAdminActiveAccount" &&
                    <Button
                        onClick={() => handleSubAdminSuspend()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }



                {selectedType === "subAdminSuspend" &&
                    <Button
                        onClick={() => handleSubAdminSuspend()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn"
                    >
                        Yes
                    </Button>
                }




                {selectedType === "subAdminDelete" &&
                    <Button
                        onClick={() => handleSubAdminDelete()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {selectedType === "subAdminrestPassword" &&
                    <Button
                        onClick={() => handlesubAdminResetPassword()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }

                {selectedType === "StudentDelete" &&
                    <Button
                        onClick={() => handleStudentDelete()}
                        variant="secondary"
                        className="modal-close-btn  pop-cancel-btn"
                    >
                        Yes
                    </Button>
                }

                {selectedType === "deleteContentVideo" &&
                    <Button
                        onClick={() => handleDeleteContentVideo()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


{selectedType === "parentActiveAccount" &&
                    <Button
                        onClick={() => handleParentActivateAction()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


{selectedType === "parentSuspend" &&
                    <Button
                        onClick={() => handleParentActivateAction()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }





{selectedType === "parentDelete" &&
                    <Button
                        onClick={() => handleParentDeleteAction()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }




{selectedType === "parentResetPassword" &&
                    <Button
                        onClick={() => handleParentresetPassAction()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                <Button
                    variant="primary"
                    onClick={handleConfirmClose}
                    className="modal-f-btn pop-cancel-btn"
                >
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal
