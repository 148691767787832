import { ErrorMessage, Field, Formik, Form as FormikForm } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { schoolParentIdListAction, schoollistAction } from '../Redux/Action/schoolAction';
import { GENDER_TYPE, groupData } from '../constant/StaticData';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import StudentsImagesModal from './StudentsImagesModal';
import { parentCreateAction, parentEditAction, parentListAction, parentdetailsAction } from '../Redux/Action/ParentAction';



const AddParentModal = ({ ParentModal, handleParentModalClose, typeModal, EditData, idval }) => {
    const dispatch = useDispatch();
    const [studentModalshow, setStudentModalshow] = useState(false)
    const [slectedImage, setSlectedImage] = useState(null)
    const schoollist = useSelector((state) => state.school.schoollist);
    const [studentEditData, seTStudentEditData] = useState({
        firstName: "",
        gender:"",
        surName: "",
        email: '',
        group: '',
        year:"",
        image: "",
        schoolId: "",

    })

    const initialValues = {
        firstName: "",
        gender:"",
        surName: "",
        email: '',
        group: '',
        year:"",
        image: "",
        schoolId: "",
    };

    useEffect(() => {
        if (typeModal === "editParent") {
            seTStudentEditData({
                email: EditData?.email,
                group: EditData?.group,
                image: EditData?.image,
                schoolId: EditData?.schoolId?._id,
                firstName: EditData?.firstName,
                gender: EditData?.gender,
                year: EditData?.year,
                surName: EditData?.surName,
            })

        } else {

            seTStudentEditData({
                firstName: "",
                    gender:"",
                surName: "",
                email: '',
                group: '',
                    year:"",
                image: "",
                schoolId: "",
            })

        }
    }, [typeModal, EditData])


    useEffect(() => {
        const payload = {
            search: "",
            page: 0,
            limit:20,
            schoolId:"",

        };
        dispatch(parentListAction(payload));
    }, [dispatch]);

    const handleSubmit = async (values) => {
        if (typeModal == "editParent") {
            const modifyData = {
                email: values.email,
                firstName: values.firstName,
                gender: values.gender,
                surName: values.surName,
                group: values.group,
                image: slectedImage ||values.image,
                year: values.year,

                schoolId: values.schoolId,
                id: idval
            }


            const res = await dispatch(parentEditAction(modifyData))
            if (res?.payload?.statusCode == 200) {
                toast.success(res.payload.message, {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
                dispatch(parentdetailsAction({ id: idval }))
                handleParentModalClose();
                setSlectedImage(null)

            } else {
                toast.error(res.payload.message)
            }
        } else {

            const modifyData = {
                email: values.email,
                firstName: values.firstName,
                gender: values.gender,
                surName: values.surName,
                group: values.group,
                image: slectedImage ||values.image,
                year: values.year,
                schoolId: values.schoolId,
            }
            const res = await dispatch(parentCreateAction(modifyData))
            if (res?.payload?.statusCode == 200) {
                toast.success("Learner added successfully!", {
                    iconTheme: {
                        primary: '#4318ff',
                        secondary: '#FFFAEE',
                    },
                })
                const Payload = {
                    id: idval,
                    searchValue: ""
                }
                dispatch(schoolParentIdListAction(Payload))
                handleParentModalClose();
                setSlectedImage(null)
            } else {
                toast.error(res.payload.message)
            }
        }

    };

    const handleDeleteFileFromS3 = async (event, file, name, setFieldValue) => {
        setSlectedImage(null)
        setFieldValue(name, "");
    };


    useEffect(() => {
        const payload = {
            searchValue: "",
            page: 0,
            type: ""
        };
        dispatch(schoollistAction(payload));
    }, [dispatch]);

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .min(2, 'Name must be at least 2 characters')
            .required('Name is required'),
        surName: Yup.string()
            .min(2, 'Last name must be at least 2 characters')
            .required('Last name is required'),
        email: Yup.string()
            .email("Please enter a valid email")
            .required("This field is required")
            .test('first-letter-lowercase', 'The first letter of the email must be lowercase', (value) => {
                if (!value) return true;
                return value[0] === value[0].toLowerCase();
            }),
        year: Yup.string()
            .required('Year  is required'),
        schoolId: Yup.string()
            .required('School selection is required'),
        gender: Yup.string()
            .required('Gender is required'),
    });


    const handleCloseStudentModal = () => {
        setStudentModalshow(false)
    }

    const handleOpenStudentModal = () => {
        setStudentModalshow(true)
    }


    return (
        <>
            <Modal size="lg" show={ParentModal} onHide={handleParentModalClose} className="comn-modal-set">
                <Modal.Header closeButton className="border-none">
                    <Modal.Title className="text-center">{typeModal === "editParent" ? "Edit Parent" : "Add Parent"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={studentEditData || initialValues}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, setFieldValue, values }) => (
                            <FormikForm>
                                <div className="upld-vd-box mt-4 mb-4">
                                    <div className="upld-img-side">
                                        { slectedImage || values.image ? (
                                            <div>
                                                <img src={slectedImage || values.image} className="img-fluid" alt='img' />
                                                <Button onClick={(event) => handleDeleteFileFromS3(event, values.image, "image", setFieldValue)} className='mt-3'>Delete</Button>
                                            </div>
                                        ) : (
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                                    <g clipPath="url(#clip0_48_1806)">
                                                        <path d="M33.3337 53.3333H46.667C48.5003 53.3333 50.0003 51.8333 50.0003 50V33.3333H55.3003C58.267 33.3333 59.767 29.7333 57.667 27.6333L42.367 12.3333C41.067 11.0333 38.967 11.0333 37.667 12.3333L22.367 27.6333C20.267 29.7333 21.7337 33.3333 24.7003 33.3333H30.0003V50C30.0003 51.8333 31.5003 53.3333 33.3337 53.3333ZM20.0003 60H60.0003C61.8337 60 63.3337 61.5 63.3337 63.3333C63.3337 65.1667 61.8337 66.6667 60.0003 66.6667H20.0003C18.167 66.6667 16.667 65.1667 16.667 63.3333C16.667 61.5 18.167 60 20.0003 60Z" fill="#4318FF" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_48_1806">
                                                            <rect width="80" height="80" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <div className="upld-text">
                                                    <h3> Update Photo</h3>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="upld-btn-side">
                                        <h2>Select</h2>
                                        <p>550 x 1200 recommended</p>
                                        <div className="mt-5" onClick={handleOpenStudentModal}>
                                            <Link to="">
                                                Update Photo
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <ErrorMessage name="image" component="div" className="text-danger" />

                                <Form.Group className="mb-3" controlId="formSurName">
                                    <Form.Label>First Name</Form.Label>
                                    <Field
                                        name="firstName"
                                        type="text"
                                        className="input-set form-control"
                                        placeholder="Type Here"
                                    />
                                    <ErrorMessage name="firstName" component="div" className="text-danger" />
                                </Form.Group>




                                <Form.Group className="mb-3" controlId="formSurName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Field
                                        name="surName"
                                        type="text"
                                        className="input-set form-control"
                                        placeholder="Type Here"
                                    />
                                    <ErrorMessage name="surName" component="div" className="text-danger" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>School Name</Form.Label>
                                    <Field
                                        name="schoolId"
                                        as="select"
                                        className="form-select"
                                    >
                                        <option value="">Select</option>
                                        {schoollist && schoollist?.school && schoollist?.school.length > 0 && schoollist?.school.map((ele, index) => {
                                            return (
                                                <option key={index} value={ele._id}>{ele.schoolName}</option>
                                            );
                                        })}
                                    </Field>
                                    <ErrorMessage name="schoolId" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Gender</Form.Label>
                                    <Select
                                        options={GENDER_TYPE}
                                        className="group"
                                        onChange={(selectedOptions) => {
                                            setFieldValue("gender", selectedOptions?.value);
                                        }}
                                        value={GENDER_TYPE?.filter(option => values?.gender == option?.value)}
                                    />
                                    <ErrorMessage name="gender" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="carSelect" className="mb-3">
                                    <Form.Label>Year Group</Form.Label>
                                    <Field as="select" name="year" id="cars" defaultValue="" className="form-control"
                                        onChange={(e) => {
                                            const selectedYear = e.target.value;
                                            setFieldValue('year', selectedYear);
                                            if (['3', '4',].includes(selectedYear)) {
                                                setFieldValue('group', 1);
                                              } else if (['5', '6'].includes(selectedYear)){
                                                setFieldValue('group', 2);
                                              } else if (['7', '8', '9'].includes(selectedYear)) {
                                                setFieldValue('group', 3);
                                            } else if (['10', '11', '12'].includes(selectedYear)) {
                                                setFieldValue('group', 4);
                                            } else {
                                                setFieldValue('group', '');
                                            }
                                        }}

                                    >
                                        <option value="" disabled>
                                            -- Select a Year Group --
                                        </option>

                                        <optgroup label="KS2 Lower">
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </optgroup>
                                        <optgroup label="KS2 Higher">
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </optgroup>

                                        <optgroup label="KS3">
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                        </optgroup>
                                        <optgroup label="KS4">
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </optgroup>
                                    </Field>
                                    <ErrorMessage name="year" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Field
                                        name="email"
                                        type="email"
                                        // disabled={typeModal === "editStudent"}
                                        className="input-set form-control"
                                        placeholder="Type Here"
                                        autoFocus
                                    />
                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                </Form.Group>

                                <Modal.Footer className="border-none justify-content-center">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="modal-f-btn pop-cancel-btn "
                                        disabled={isSubmitting}
                                    >
                                        {typeModal === "editParent" ? `Update` : `Create`}
                                    </Button>
                                </Modal.Footer>
                            </FormikForm>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <StudentsImagesModal studentModalshow={studentModalshow} handleCloseStudentModal={handleCloseStudentModal} setSlectedImage={setSlectedImage} />
        </>
    );
};

export default AddParentModal;