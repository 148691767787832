import React from "react";
import { FadeLoader } from "react-spinners";

const LoaderComponent = () => {

  return (
    <>
        <div className="loader-style container-fluid">
          <FadeLoader
            className="fade-loader"
            loading={true}
            color={"#D61F7F"}
          />
        </div>
    
    </>
  );
};

export default LoaderComponent;


